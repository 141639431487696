import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function UvPrinting({ location, data }) {
  return (
    <Page title={"What is UV printing – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>WHAT IS UV printing? </div>}
        subtitle=""
        text={
          <div>
            <p>UV printing is a form of digital printing that uses ultra-violet lights to dry or cure ink as it is
              printed. As the printer distributes ink on the surface of a material (called a “substrate”), specially
              designed UV lights follow close behind, instantly curing (or drying) the ink.</p>
            <p>There are many advantages of this printing method:</p>
            <ol>
              <li>Printing on different surfaces like leather, leatherette (vegan leather), linen, fabrics, wood, glass,
                acrylic etc.
              </li>
              <li>Being able to print different graphics, photos or text in many sizes and colours. We can print your
                logo, monogram, custom graphic or text using different fonts, colours and sizes.
              </li>
              <li>Outstanding resolution and quality</li>
              <li>Long lasting</li>
            </ol>
          </div>
        }
        data={data}
        image={"uvPrintingWhatIs"}
      />

      <TextRight
        title={<div>UV Printing </div>}
        subtitle="on Linen"
        text={
          <div>
            &nbsp;
          </div>
        }
        data={data}
        image={"uvPrintingOnLinen"}
      />

      <TextLeft
        title={<div>UV Printing </div>}
        subtitle="on Leatherette"
        text={
          <div>
            &nbsp;
          </div>
        }
        data={data}
        image={"uvPrintingOnLeatherette"}
      />

      <TextRight
        title={<div>UV Printing </div>}
        subtitle="on Leather"
        text={
          <div>
            &nbsp;
          </div>
        }
        data={data}
        image={"uvPrintingOnLeather"}
      />

      <TextLeft
        title={<div>UV Printing </div>}
        subtitle="on Wood"
        text={
          <div>
            &nbsp;
          </div>
        }
        data={data}
        image={"uvPrintingOnWood"}
      />


    </Page>
  )
}


export const query = graphql`
  query {
    uvPrintingWhatIs: file(relativePath: { eq: "uvPrintingWhatIs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
   uvPrintingOnLinen: file(relativePath: { eq: "uvPrintingOnLinen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    uvPrintingOnLeatherette: file(relativePath: { eq: "uvPrintingOnLeatherette.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    uvPrintingOnLeather: file(relativePath: { eq: "uvPrintingOnLeather.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    uvPrintingOnWood: file(relativePath: { eq: "uvPrintingOnWood.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
   
    
  }
`